let url = () =>{
    let url
    if(process.env.NODE_ENV === 'development') {
        // url = `http://139.162.234.164:81`,
        url = `https://staging.afcfta.app`
    } else {
        // url = `http://139.162.234.164:81`
        // url = `api.fiscoins.com/api/
        url = `https://main.afcfta.app`
    }
    return url
}

export default url;