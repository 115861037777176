<template>
  <div class="contaner">
    <Alert :message="marketsError" type="alert-danger" v-if="marketsError" />
    <Alert
      :message="suspendSuccess"
      type="alert-success"
      v-if="suspendSuccess"
    />
    <Alert :message="suspendError" type="alert-danger" v-if="suspendError" />

    <div class="row">
      <div class="col-12 my-3">
        <h2
          class="my-3  text-secondary"
          style="font-size:1.2em; display:inline-block"
        >
          Entities
        </h2>
      </div>
    </div>

    <div class="card shadow-sm">
      <div class="card-header">
        <h3 class="my text-secondary" style="font-size:1em;">Entities</h3>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Entity Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(entity, key) in entities" :key="key">
                <td>{{ entity.id }}</td>
                <td>{{ entity.name }}</td>
                <td class="actions">
                  <!-- <button 
                            @click="getDetails(entity.id,entity.name,entity.market_name,entity.type, entity.email,entity.phone, key)" 
                            class="btn btn-success mr-1"
                              data-toggle="modal" data-target="#updateMarket">
                              <i class="far fa-edit">   </i> edit
                          </button> -->

                  <div class="dropdown">
                    <a
                      href="#"
                      data-toggle="dropdown"
                      data-caret="false"
                      class="text-muted"
                      ><b-icon-three-dots></b-icon-three-dots
                    ></a>
                    <div
                      class="dropdown-menu dropdown-menu-right p-3"
                      style="text-align:center"
                    >
                      <button
                        href="#"
                        class="btn"
                        @click="entityDetail(entity.id, entity.key)"
                        data-toggle="modal"
                        data-target="#entityDetails"
                      >
                        view
                      </button>
                      <button
                        v-if="!entity.suspended"
                        @click="suspendEntity(entity.id, key)"
                        class="btn text-danger"
                        data-toggle="modal"
                        data-target="#deleteMarket"
                      >
                        suspend
                      </button>
                      <button
                        v-if="entity.suspended"
                        @click="unsuspendEntity(entity.id, key)"
                        class="btn text-primary"
                        data-toggle="modal"
                        data-target="#deleteMarket"
                      >
                        unsuspend
                      </button>
                      <button
                        v-if="!entity.verified_afcfta_number"
                        data-toggle="modal"
                        data-target="#verifyAfcftaNumber"
                        class="btn text-success"
                        @click="entityID(entity.id, key)"
                      >
                        Verify AfCFTA Number
                      </button>
                      <button
                        class="btn text-secondary"
                        data-toggle="modal"
                        data-target="#allowDataProvider"
                        @click="
                          getDetails(
                            entity.id,
                            entity.name,
                            entity.market_name,
                            entity.type,
                            entity.email,
                            entity.phone,
                            key
                          )
                        "
                      >
                        assign data provider
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
      </div>
    </div>
    <!--add entity modal content -->
    <div
      class="modal fade"
      id="addEntity"
      tabindex="-1"
      aria-labelledby="add market"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              style="float:left; color:red; font-weight:bold;font-size:1.3em"
              id="exampleModalLabel"
            >
              Add Entity
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center" v-if="success">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-success" role="alert">
                  <strong>{{ success }}</strong>
                </div>
              </div>
            </div>
            <div class="row justify-content-center" v-if="error">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-danger" role="alert">
                  <strong>{{ error }}</strong>
                </div>
              </div>
            </div>
            <form class="" @submit.prevent="addEntity">
              <div class="form-row">
                <div class="form-group mb-2 col-md-6">
                  <label for="organization" class="form-label"
                    >Organization Name:</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="organization"
                    required
                    placeholder="Name of Agency or Oganization"
                    v-model.trim="name"
                  />
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="market" class="form-label">Select Market:</label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model.trim="market"
                    >k
                    <option
                      v-for="(market, key) in markets"
                      :key="key"
                      :value="market.id"
                      >{{ market.name }}</option
                    >
                  </select>
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="email" class="form-label">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    required
                    placeholder="me@example.com"
                    v-model.trim="email"
                  />
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="exampleFormControlSelect1"
                    >Select Market Type:</label
                  >
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model.trim="type"
                  >
                    <option>Organisation</option>
                    <option>Outlet</option>
                    <option>Agency</option>
                  </select>
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="staticEmail2" class="form-label"
                    >Phone Number:</label
                  >
                  <vue-tel-input v-model="phone"></vue-tel-input>
                </div>
                <div class="col-md-6 py-3">
                  <button type="submit" class="btn btn-danger  my-3 addbutton">
                    <span
                      v-if="loading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Add market
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end of modal content -->
    <!--  update entity modal content -->
    <div
      class="modal fade"
      id="updateMarket"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              style="color:red; font-weight:bold;font-size:1.3em"
              id="exampleModalLabel"
            >
              Update Entity
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center" v-if="success">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-success" role="alert">
                  <strong>{{ success }}</strong>
                </div>
              </div>
            </div>
            <div class="row justify-content-center" v-if="error">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-danger" role="alert">
                  <strong>{{ error }}</strong>
                </div>
              </div>
            </div>
            <form class="" @submit.prevent="updateEntity">
              <div class="form-row">
                <div class="form-group mb-2 col-md-6">
                  <label for="organization" class="form-label"
                    >Organization Name:</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="organization"
                    required
                    placeholder="Name of Agency or Oganization"
                    v-model.trim="name"
                  />
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="market" class="form-label">Select Market:</label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model.trim="market"
                  >
                    <option
                      v-for="(market, key) in markets"
                      :key="key"
                      :value="market.id"
                      >{{ market.name }}</option
                    >
                  </select>
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="email" class="form-label">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    required
                    placeholder="me@example.com"
                    v-model.trim="email"
                  />
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="exampleFormControlSelect1"
                    >Select Market Type:</label
                  >
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model.trim="type"
                  >
                    <option>Organisation</option>
                    <option>Outlet</option>
                    <option>Agency</option>
                  </select>
                </div>
                <div class="form-group mb-2 col-md-6">
                  <label for="staticEmail2" class="form-label"
                    >Phone Number:</label
                  >
                  <vue-tel-input v-model="phone"></vue-tel-input>
                </div>
                <div class="col-md-6 py-3">
                  <button
                    type="submit"
                    class="btn btn-danger my-2 btn-lg addbutton"
                  >
                    <span
                      v-if="loading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- End Edit -->
    <!-- Start of assign data provider -->
    <div
      class="modal fade"
      id="allowDataProvider"
      tabindex="-1"
      aria-labelledby="Delete Market"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              style="float:left; color:red; font-weight:bold;font-size:1.3em"
              id="exampleModalLabel"
            >
              Assign Data Provider
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center" v-if="success">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-success" role="alert">
                  <strong>{{ success }}</strong>
                </div>
              </div>
            </div>
            <div class="row justify-content-center" v-if="error">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-danger" role="alert">
                  <strong>{{ error }}</strong>
                </div>
              </div>
            </div>
            <select
              name="asigndataprovider"
              v-model="data_provider_type"
              class="form-control"
              id=""
            >
              <option value="Regulatory">Regulatory</option>
              <option value="Financial">Financial</option>
            </select>
            <button
              :disabled="success"
              style="float:right"
              class="btn btn-danger mt-3"
              @click="assignDataProvider"
            >
              Assign
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end of allow data provider modal content -->
    <!-- VERIFY AFCFTA NUMBER -->
    <div
      class="modal fade"
      id="verifyAfcftaNumber"
      tabindex="-1"
      aria-labelledby="Delete Market"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              style="float:left; color:red; font-weight:bold;font-size:1.3em"
              id="exampleModalLabel"
            >
              Verify Entity AfCFTA Number
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center" v-if="success">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-success" role="alert">
                  <strong>{{ success }}</strong>
                </div>
              </div>
            </div>
            <div class="row justify-content-center" v-if="error">
              <div class="col-lg-11 col-md-8 col-sm-11">
                <div class="text-center alert alert-danger" role="alert">
                  <strong>{{ error }}</strong>
                </div>
              </div>
            </div>
            <button
              :disabled="success"
              style="float:right"
              class="btn btn-danger"
              @click="verifyEntityAfcftaNumber"
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END OF VERIFY AFCFTA NUMBER -->
    <!-- start of  entity details modal  content -->
    <div
      class="modal fade"
      id="entityDetails"
      tabindex="-1"
      aria-labelledby="Delete Market"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-dark"
              style="color:red; font-weight:bold;font-size:1.3em"
              id="exampleModalLabel "
            >
              Entity Detail
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card border-left-danger m-4">
                <div class="card-body">
                  <p>
                    <span>Name</span>:<strong>{{ entity.name }}</strong>
                  </p>
                  <p>
                    <span>Market Name</span>:<strong>{{
                      entity.market_name
                    }}</strong>
                  </p>
                  <p>
                    <span>Data Provider</span>:<strong>{{
                      entity.data_provider ? 'Yes' : 'No'
                    }}</strong>
                  </p>
                  <p>
                    <span>Type</span>:<strong>{{ entity.type }}</strong>
                  </p>
                  <p>
                    <span>AFCFTA Number</span>:<strong>{{
                      entity.afcfta_number
                    }}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of entity details modal content -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { VueTelInput } from 'vue-tel-input';
import Alert from '@/components/Alert.vue';
const $ = require('jquery');
export default {
  name: 'entities',
  components: {
    VueTelInput: VueTelInput,
    Alert: Alert,
  },
  data() {
    return {
      name: null,
      market: null,
      type: null,
      phone: null,
      email: null,
      addError: null,
      marketsError: null,
      key: null,
      id: null,
      entity: {},
      data_provider_type: 'Regulatory',

      success: null,
      error: null,
      loading: false,
      suspendSuccess: null,
      suspendError: null,
    };
  },
  methods: {
    entityID(id, key) {
      this.key = key;
      this.id = id;
    },
    verifyEntityAfcftaNumber(id, key) {
      this.error = null;
      this.success = null;

      const data = {
        id: this.id,
        key: this.key,
      };
      this.$store
        .dispatch('verifyEntityAfcftaNumber', data)
        .then((response) => {
          console.log(response);
          this.success = 'Entity AfCFTA Number Verified';
        })
        .catch((err) => {
          if (err?.response?.data === undefined) {
            this.error = 'Network Error.';
          }
        });
    },
    clear() {
      this.error = null;
      this.success = null;
      this.name = null;
      this.market = null;
      this.type = null;
      this.phone = null;
      this.email = null;
    },
    assignDataProvider() {
      this.error = null;
      this.success = null;

      const data = {
        data_provider_type: this.data_provider_type,
      };

      this.$store
        .dispatch('assignDataProvider', {
          key: this.key,
          id: this.id,
          data: data,
        })
        .then((response) => {
          this.success = 'Assigned';
          console.log(response);
        })
        .catch((err) => {
          if (err?.response?.data === undefined) {
            this.error === 'Network Error.';
          }
        });
    },
    entityDetail(entitiyid, key) {
      this.entity = this.entities.find((entity) => entity.id === entitiyid);
    },
    getDetails(id, name, market, type, email, phone, key) {
      // reset error and success messages to to null
      this.error = null;
      this.success = null;

      this.id = id;
      this.name = name;
      this.market = market;
      this.type = type;
      this.email = email;
      this.phone = phone;
      this.key = key;
    },
    updateEntity() {
      this.error = null;
      this.success = null;

      const data = {
        id: this.id,
        name: this.name,
        market: this.market,
        email: this.email,
        phone: this.phone,
        type: this.type,
        key: this.key,
      };
      this.$store
        .dispatch('updateEntity', data)
        .then((response) => {
          this.success = 'Update success.';
        })
        .catch((err) => {
          console.log('err', err);
          if (err?.response?.data === undefined) {
            this.error = 'Network Error.';
          }
        });
    },
    suspendEntity(id, key) {
      this.error = null;
      this.success = null;
      const data = {
        id: id,
        key: key,
      };
      this.$store
        .dispatch('suspendEntity', data)
        .then((response) => {
          console.log(response);
          this.suspendSuccess = 'Entity Suspended.';
        })
        .catch((err) => {
          console.log('error', err);
          if (err?.response?.data === undefined) {
            this.suspendError = 'Network Error.';
          }
        });
    },
    unsuspendEntity(id, key) {
      this.suspendSuccess = null;
      this.suspendError = null;
      const data = {
        id: id,
        key: key,
      };
      this.$store
        .dispatch('unsuspendEntity', data)
        .then((response) => {
          console.log(response);
          this.suspendSuccess = 'Entity suspended.';
        })
        .catch((err) => {
          if (err?.response?.data === undefined) {
            this.suspendError = 'Network Error.';
          }
        });
    },
    addEntity() {
      this.error = null;
      this.success = null;
      this.loading = true;

      const data = {
        name: this.name,
        market: this.market,
        type: this.type,
        email: this.email,
        phone: this.phone,
      };
      this.$store
        .dispatch('addEntity', data)
        .then((response) => {
          this.loading = false;
          this.success = 'Entity created successfully.';
          this.name = null;
          this.market = null;
          this.type = null;
          this.email = null;
          this.phone = null;
        })
        .catch((err) => {
          if (err?.response?.status === 422) {
            this.loading = false;
            this.error = 'Entity already exist.';
          }
          if (err?.response?.data === undefined) {
            this.loading = false;
            this.error = 'Network error.';
          }
        });
    },
    getEntities() {
      this.$store
        .dispatch('getEntities')
        .then((response) =>
          setTimeout(function() {
            $('#dataTable').DataTable();
          }, 100)
        )
        .catch((err) => {
          if (err?.response?.data === undefined) {
            this.error = 'Network Error.';
          }
        });
    },
  },
  computed: {
    ...mapGetters(['markets', 'entities']),
    details() {
      return this.entityDetail();
    },
  },
  created() {
    this.getEntities();
  },
};
</script>
<style>
.addbutton {
  width: 100%;
  font-size: 1em;
}
.btn,
table tr td {
  font-size: 0.9em;
}
</style>
