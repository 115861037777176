let service = () =>{
    let service
    if(process.env.NODE_ENV === 'development') {
        service = `service=http://localhost:8085`
    } else {
        // staging service
        //service = `service=http://test.admin.afcfta.app/`
        // production service
         service = `service=https://admin.afcfta.app/`
       
    }
    return service
}

export default service;