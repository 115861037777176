import url from '../../url'
import axios from 'axios' 
import Storage from '@/helpers/storage.js'

export const state = {
    entities: [],
    users: [],
    allTokens: []
}

export const actions = {
    getEntities({commit}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/entity/`)
                .then(response => {
                    if(response.status === 200) {
                        commit('set_entities',response.data)
                    }
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    getUsers({commit}) {
        return new Promise( (resolve, reject) => {
            axios.get(`${url()}/individuals`)
                .then(response => {
                    if(response.status === 200) {
                        commit('set_individuals',response.data)
                    }
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
    suspendEntity({commit},entity) {
        return new Promise((resolve,reject)=> {
            axios.delete(`${url()}/entity/${entity.id}/`, {
                headers: {
                'Authorization': `Token ${Storage.get('token')}`
                }})
                .then(response => {
                    resolve(response)
                    commit('update_entity',{key:entity.key, entity:response.data})
                }).catch(err => reject(err))
        })
    },
    unsuspendEntity({commit}, {id, key}) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = Storage.get('token')
            axios.patch(`${url()}/entity-unsuspend/${id}`)
             .then(response => {
                 if(response.status === 200 ) {
                  commit('update_entity', {key:key, entity:response.data})   
                 }
                 resolve(response)
             }).catch(err => {
                 console.log(err)
                 reject(err)
             })
        }) 
    },
    assignDataProvider({commit}, data) {
       
        return new Promise((resolve,reject)=> {
            axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
            axios.patch(`${url()}/data_provider/assign/${data.id}`, data.data
           )
                .then(response => {
                    commit('update_entity',{key:data.key,entity:response.data})
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    verifyIndividualAfcftaNumber({commit},data) {
        return new Promise((resolve,reject)=> {
            axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
            axios.get(`${url()}/verify-individual-afcfta/${data.id}`)
                .then(response => {
                    commit('verify_user',data.key)
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    verifyEntityAfcftaNumber({commit},data) {
        return new Promise((resolve,reject)=> {
            axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
            axios.get(`${url()}/verify-entity-afcfta/${data.id}`)
                .then(response => {
                    commit('verify_entity',data.key)
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    fetchAllTokens({commit}) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
            axios.get(`${url()}/tokens`)
                .then(response => {
                    commit('add_token', response.data)
                    resolve(response)
                }).catch( err => { reject(err)})
        })
    },
    approveToken({commit},id) {
        return new Promise((resolve,reject)=> {
            axios.defaults.headers.common['Authorization'] = `${Storage.get('token')}`
            axios.patch(`${url()}/token/approve/${id}`)
                .then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
}

export const mutations = {
    add_token(state, token) {
       state.allTokens = token 
    },
    set_entities(state,entities) {
        state.entities = entities
    },
    verify_entity(state, key) {
        state.entities[key].verified_afcfta_number = !state.entities[key].verified_afcfta_number
    },
    verify_user(state, key) {
        state.users[key].verified_afcfta_number = !state.users[key].verified_afcfta_number
    },
    set_individuals(state, users) {
        state.users = users
    }
}
export const getters = {
    entities: state => state.entities,
    users: state => state.users,
    allTokens: state => state.allTokens
}